const isDevelopment = process.env.NODE_ENV === 'development';

export const EXTENSION_ID = 'hjicnemefbakbanoigbaonlpgflhggdf';

export const firebaseConfig = {
  apiKey: 'AIzaSyARMn6g8Jo5TLqJ0NAnd7DqkRrz3HsRK5o',
  authDomain: 'app.tabmanager.io',
  projectId: 'tabmanager-io',
  storageBucket: 'tabmanager-io.appspot.com',
  messagingSenderId: '746042928103',
  appId: '1:746042928103:web:34f72b706c5a9eea222b42',
};

export const firebaseEmulatorConfig = {
  auth: 'http://localhost:9099/',
  firestore: {
    host: 'localhost',
    port: 8080,
  },
  functions: {
    host: 'localhost',
    port: 5001,
  },
  testEmail: 'test@gmail.com',
  testPassword: 'test12345'
};

/* Stripe */
const STRIPE_TEST_PUBLIC_KEY = 'pk_test_Jo5WeTTaTvTfVSiuDdSZgKWr';
const STRIPE_PROD_PUBLIC_KEY = 'pk_live_rWlmNmxCPkQoHy9Ufk9YBSFt';

const STRIPE_TEST_ADVANCED_PLAN_PRICE_ID = 'price_1IYHnaGEnkf6NMsuQSRMUFzO';
const STRIPE_PROD_ADVANCED_PLAN_PRICE_ID = 'price_1IYEZyGEnkf6NMsuujvznVeG'; // $2/month
const STRIPE_PROD_NEW_ADVANCED_PLAN_PRICE_ID = 'price_1Mdy3TGEnkf6NMsumJRIknBS'; // $3/month

export const STRIPE_PUBLIC_KEY = isDevelopment ? STRIPE_TEST_PUBLIC_KEY : STRIPE_PROD_PUBLIC_KEY;
export const ADVANCED_PLAN_PRICE_ID = isDevelopment
  ? STRIPE_TEST_ADVANCED_PLAN_PRICE_ID
  : STRIPE_PROD_NEW_ADVANCED_PLAN_PRICE_ID;

/* API */
const API_TEST_URL = 'http://localhost:5001/tabmanager-io/us-central1/';
const API_PROD_URL = 'https://us-central1-tabmanager-io.cloudfunctions.net/';

export const API_URL = isDevelopment ?  API_TEST_URL : API_PROD_URL;
export const CREATE_CUSTOMER_PORTAL_URL = `${API_URL}stripeCheckout-createCustomerPortal`;
export const CREATE_CHECKOUT_URL = `${API_URL}stripeCheckout-createCheckoutSession`;

/* Bucket */
const BUCKET_NAME = 'tabmanager-io.appspot.com';
export const PUBLIC_WINDOWS_URL = `https://storage.googleapis.com/${BUCKET_NAME}/windows/`;

/* Subscription */
export const SubscriptionPlan = {
  FREE: 'free',
  ADVANCED: 'advanced'
};
